import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layouts/default"

import Breadcrumb from "../components/breadcrumb"
import Bullet from "../components/bullet"
import BulletList from "../components/bullet-list"
import Column from "../components/column"
import Columns from "../components/columns"
import Constrain from "../components/constrain"
import Paragraph from "../components/paragraph"
import Grid from "../components/grid"
import Heading from "../components/heading"
import Inline from "../components/inline"
import Button from "../components/button"
import Image from "../components/image"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import SectionSpacing from "../components/section-spacing"
import Trivia from "../components/trivia"
import TextLink from "../components/textlink"

const Francais = () => {
  const data = useStaticQuery(graphql`
    query SprachenFrancaisQuery {
      turmzimmer: file(relativePath: { eq: "sprachen/turmzimmer.jpg" }) {
        ...largeImage
      }
      interaktion: file(relativePath: { eq: "sprachen/interaktion.jpg" }) {
        ...largeImage
      }
      medienstation: file(relativePath: { eq: "sprachen/medienstation.jpg" }) {
        ...largeImage
      }
      laufstrecke: file(relativePath: { eq: "sprachen/laufstrecke.jpg" }) {
        ...largeImage
      }
      garten: file(relativePath: { eq: "sprachen/garten.jpg" }) {
        ...largeImage
      }
      nummern: file(relativePath: { eq: "sprachen/nummern.jpg" }) {
        ...mediumImage
      }
      english: file(relativePath: { eq: "sprachen/english.jpg" }) {
        ...mediumImage
      }
      stadtfriedhof: file(relativePath: { eq: "sprachen/stadtfriedhof.jpg" }) {
        ...mediumImage
      }
      stift: file(relativePath: { eq: "sprachen/evangelisches-stift.jpg" }) {
        ...mediumImage
      }
      burse: file(relativePath: { eq: "sprachen/burse.jpg" }) {
        ...mediumImage
      }
      aussenansicht: file(
        relativePath: { eq: "sprachen/turm-aussenansicht.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 400, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Français"
        description="La tour de Hölderlin porte le nom du poète Friedrich Hölderlin (1770-1843). De 1806 à 1843 il y a passé la deuxième moitié de sa vie. Ici il a écrit ses dernières poèmes qu'il signait souvent sous le pseudonyme »Scardanelli«. Ici il a reçu des contemporains, des admirateurs et des autres écrivains."
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Français",
                link: "/francais",
              },
            ]}
          />
          <PageTitle>La tour de Hölderlin</PageTitle>
          <Paragraph dropcap={true}>
            La tour de Hölderlin porte le nom du poète Friedrich Hölderlin
            (1770-1843). De 1806 à 1843 il y a passé la deuxième moitié de sa
            vie. Ici il a écrit ses dernières poèmes qu'il signait souvent sous
            le pseudonyme »Scardanelli«. Ici il a reçu des contemporains, des
            admirateurs et des autres écrivains.
          </Paragraph>
          <Image
            image={data.turmzimmer.childImageSharp.gatsbyImageData}
            caption="La chambre de Hölderlin aujourd'hui."
            alt="Il y a un petit bureau au milieu de la pièce. Le soleil brille à travers les fenêtres sur les vieux parquet."
          />
          <Constrain>
            <Paragraph lang="fr">
              Aujourd'hui Hölderlin est considéré comme l'un des poètes les plus
              importants de langue allemande. La tour, où il a passé les 36
              dernières années de sa vie, fait partie des monuments littéraires
              de premier plan au monde. Elle a été intégré dans maintes œuvres
              littéraires, musicales ou artistiques.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            L'exposition permanente
          </Heading>
          <Grid columns={[1, 2]} space={"px"}>
            <Image
              image={data.medienstation.childImageSharp.gatsbyImageData}
              alt="Une station d'écoute sur la réception de Hölderlin en musique et littérature de l'exposition permanente."
              attribution="David Franck"
            />
            <Image
              image={data.interaktion.childImageSharp.gatsbyImageData}
              alt="Une station d'écoute de poèmes de l'exposition. Les intonations du poème sont transmises à une planche de bois par des vibrations."
              attribution="David Franck"
            />
          </Grid>
          <Constrain>
            <Paragraph lang="fr">
              L'exposition permanente du musée est consacrée aux périodes de la
              vie de Hölderlin que celui-ci a passé à Tübingen: ses années
              d'études au séminaire de théologie protestante de Tübingen
              (1788-1793) et ses dernières 36 années qu'il a passé dans la tour
              chez la famille du menuisier Ernst Zimmer. Elle présente des
              témoignages d'amitié avec les futurs philosophes Hegel et
              Schelling, ses collègues au séminaire de théologie protestante,
              ainsi que ses premiers poèmes publiés de cette époque. Elle met en
              lumière la beauté des poèmes écrits dans la tour et permet de
              faire la connaissance du travail de Hölderlin sur la langue et le
              rythme de sa poésie d'une manière qui s'adresse aux sens.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="fr">
              Au-delà de la poésie écrite à Tübingen, l'exposition jette un
              regard sur l'œuvre complète de Hölderlin. Maints exemples pris de
              la littérature, de la musique et de l'art expliquent l'incidence
              de cette œuvre sur les époques suivantes jusqu'à présent.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="fr">
              Des expositions temporaires éclairent Hölderlin et ses œuvres sous
              différents angles. Des artistes, des chercheurs et des
              chercheuses, des lecteurs et des lectrices présentent leurs
              regards sur le poète et leurs approches à ses œuvres.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            Audioguide et traductions en français
          </Heading>
          <Grid columns={[1, 2]} space={"px"}>
            <Image
              image={data.english.childImageSharp.gatsbyImageData}
              alt="Le menu de démarrage d'un périphérique de guide multimédia."
            />
            <Image
              image={data.nummern.childImageSharp.gatsbyImageData}
              alt="Une personne tape un nombre dans le dispositif de guide média."
            />
          </Grid>
          <Constrain>
            <Paragraph lang="fr">
              ll y a un média-guide pour l'exposition permanente. Il contient
              une visite audio en français et des traductions en français de
              tous les textes de l'exposition. Vous pouvez emprunter le
              média-guide en tant qu'appareil à la caisse du musée ou le
              télécharger en tant qu'application sur votre portable pour pouvoir
              l'utiliser en dehors du musée. Avec la visite audio vous pouvez
              organiser votre propre parcours à travers l'exposition. Piéce par
              pièce. Ou d'un objet à l'autre.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="fr">
              À côté de tous les textes de l'exposition vous trouvez un symbole
              répétitif avec un numéro alternatif. Si vous tapez le numéro dans
              le média-guide, vous obtiendrez le texte approprié. Si vous le
              préférez, vous pouvez lire aussi les textes anglais pièce par
              pièce d'une manière indépendante.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Inline space={3}>
              <Button href="https://play.google.com/store/apps/details?id=de.droidsolutions.tuebingen">
                Google Play Store
              </Button>
              <Button href="https://apps.apple.com/de/app/literaturpfad-t%C3%BCbingen/id1471621109">
                App Store
              </Button>
            </Inline>
          </Constrain>
        </Stack>

        <Constrain align="right">
          <Stack>
            <Heading as="h2" level={2}>
              Visites guidées en français
            </Heading>
            <Paragraph lang="fr">
              Le musée vous propose des visites guidées en langue française.
              Pour cela, vous devez vous inscrire 14 jours à l'avance{" "}
              <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                par e-mail
              </TextLink>
              .
            </Paragraph>
            <Paragraph lang="fr">
              Durée de la visite: 60 minutes. Le nombre des participants est
              limité à 15 personnes.
            </Paragraph>
            <Paragraph lang="fr">
              Frais de la visite: 60 euros par groupe.
            </Paragraph>
          </Stack>
        </Constrain>

        <Stack>
          <Heading as="h2" level={2}>
            Le jardin du musée
          </Heading>
          <Image
            image={data.garten.childImageSharp.gatsbyImageData}
            caption="Le jardin du musée aujourd'hui. Le directeur de la clinique, Johann Ferdinand Autenrieth, avait fait construire les escaliers pour procurer à la clnique un accés à l'eau"
            alt="Un grand escalier en pierre mène au jardin du musée et aux rives du Neckar."
            attribution="Gudrun de Maddalena"
          />
          <Constrain>
            <Paragraph lang="fr">
              La tour d'Hölderlin possède également un jardin. Au temps de
              Hölderlin ce jardin appartenait à la clinique en face, où
              Hölderlin fut hospitalisé en psychiatrie de septembre 1805 à mai
              1806. Il était considéré comme »aliéné« et subit une thérapie de
              force de 231 jours. D'après son livre d'ordonnances, il semble
              qu'on lui ait également prescrit une promenade, qu'il fît très
              probabalement dans ce jardin, juste à côté de sa future demeure.
              Lorsque Hölderlin sortit enfin de l'hôpital, le menuisier Ernst
              Zimmer accueillit chez lui.
            </Paragraph>
          </Constrain>
          <Constrain>
            <Paragraph lang="fr">
              L'exposition permanente se poursuit dans le jardin. Hölderlin
              était un grand randonneur. Il a souvent travaillé ses poèmes en
              marchant. Sur un chemin spécialement créé à ce but, vous pouvez
              écouter trois poèmes de Friedrich Hölderlin à trois vitesses
              différentes. Vous pouvez choisir un des trois poèmes avec le
              média-guide et vous pouvez également choisir la vitesse de la
              lecture: modérée, accélérée ou rapide. Puis vous vous promenez sur
              ce chemin en écoutant le poème.
            </Paragraph>
          </Constrain>
          <Image
            image={data.laufstrecke.childImageSharp.gatsbyImageData}
            alt="Un chemin sinueux dans le jardin du musée."
          />
          <Constrain>
            <Paragraph lang="fr">
              Vous pouvez essayer la vitesse qui convient le mieux au poème. Si
              le poème est lu lentement, vous avez plus de temps pour atteindre
              la fin du chemin. Si le poème est lu rapidement, vous devez vous
              dépêcher pour arriver à la fin du chemin en même temps que le
              poème est terminé.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            Comment joindre la tour de Hölderlin
          </Heading>
          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.aussenansicht.childImageSharp.gatsbyImageData}
              alt="La tour Hölderlin sur le Tübingen Neckar: Un bâtiment jaune avec une tour ronde et un toit pointu."
              attribution="David Franck"
            />
            <Stack space={6}>
              <Heading as="p" level={5}>
                Museum Hölderlinturm
              </Heading>
              <Paragraph lang="fr">
                Bursagasse 6 <br />
                72070 Tübingen
              </Paragraph>
            </Stack>
          </Grid>
          <Constrain>
            <Trivia title="veuillez noter" size={3}>
              Veuillez noter que la tour de Hölderlin n'est pas accessible sans
              barrières. Vous ne pouvez pas vous rendre en chaise roulante.
            </Trivia>
          </Constrain>

          <Stack space={[12, 24]}>
            <Constrain>
              <Stack>
                <Stack space={6}>
                  <Heading as="h3" level={4}>
                    Si vous venez en bus:
                  </Heading>
                  <Paragraph lang="fr">
                    Prenez les lignes 1-23. L'arrêt de bus s'appelle
                    »Neckarbrücke«.
                  </Paragraph>
                </Stack>
                <Paragraph lang="fr">
                  Au pont du Neckar il y a une maison avec deux restaurants: le
                  restaurant »La Torre Pizzeria« et le restaurant »Sanbao«.
                  Directement à côté de cette maison il y a un escalier qui vous
                  mène aux remparts de la ville. En longeant le petit sentier
                  aux remparts vous arrivez à la tour. L'entrée du musée est
                  indique par un panneau avec l'inscription »Museum«.
                </Paragraph>
              </Stack>
            </Constrain>
            <Constrain>
              <Stack>
                <Stack space={6}>
                  <Heading as="h3" level={4}>
                    Si vous venez en voiture:
                  </Heading>
                  <Paragraph lang="fr">
                    Vous pouvez garer la voiture au Neckar-Parkhaus. L'adresse:
                    Wöhrdstraße 11, 72072 Tübingen
                  </Paragraph>
                </Stack>
                <Paragraph lang="fr">
                  Si vous sortez du garage, tournez à droite. Vous arrivez au
                  pont du Neckar. Traversez la rue au feu, pui traversez le
                  pont. À l'autre bout du pont il y a une maison avec deux
                  restaurants: le restaurant »La Torre Pizzeria« et le
                  restaurant »Sanbao«. Directement à côté de cette maison il y a
                  un escalier qui vous mène aux remparts de la ville. En
                  longeant le petit sentier aux remparts vous arrivez à la tour.
                  L'entrée du musée est indique par un panneau avec
                  l'inscription »Museum«.
                </Paragraph>
              </Stack>
            </Constrain>
          </Stack>
        </Stack>

        <Stack>
          <Heading as="h2" level={2}>
            Pour plus d'informations sur Friedrich Hölderlin en français
          </Heading>
          <Constrain>
            <BulletList size={[3, 3, 4]}>
              <Bullet>
                <TextLink href="https://fr.wikipedia.org/wiki/Friedrich_H%C3%B6lderlin">
                  Wikipedia
                </TextLink>
              </Bullet>
              <Bullet>
                Hölderlin: Œuvres, publ. de Philippe Jaccottet, Paris 1967.
              </Bullet>
              <Bullet>
                Pierre Bertaux: Hoelderlin ou le temps d'un poète, Paris 1983.
              </Bullet>
              <Bullet>
                Wilhelm Waiblinger: Vie, poésie et folie de Friedrich Hölderlin,
                traduction, Paris 1994.
              </Bullet>
              <Bullet>
                André Alter: Hölderlin. Le chemin de lumière, Seyssel 1992.
              </Bullet>
            </BulletList>
          </Constrain>
        </Stack>

        <Stack space={[12, 24]}>
          <Heading as="h2" level={2}>
            D'autres lieux concernant Friedrich Hölderlin à Tübingen
          </Heading>
          <Columns collapse={[true, false]} space={[6, 12]}>
            <Column width={["100%", "1/3"]}>
              <Image
                image={data.stift.childImageSharp.gatsbyImageData}
                alt="Une porte mène au parvis du monastère. La partie arrière du bâtiment montre encore les colombages historiques."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack>
                <Stack space={3}>
                  <Heading level={3}>Das Evangelische Stift</Heading>
                  <Heading color="whisper">
                    Le Séminaire de théologie protestante
                  </Heading>
                </Stack>
                <Paragraph lang="fr">
                  Le séminaire de théologie protestante est une maison d'études
                  où vivent toujours des étudiants et étudiantes en théologie.
                  Ils y sont logés et nourris. Friedrich Hölderlin a également
                  logé au séminaire. Il y a rencontré ses amis Magenau, Neuffer,
                  Hegel et Schelling.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={[6, 12]}>
            <Column width={["100%", "1/3"]}>
              <Image
                image={data.burse.childImageSharp.gatsbyImageData}
                alt="De la rive opposée du Neckar, vous pouvez voir un bâtiment rose avec deux grandes ailes derrière le mur de la ville."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack>
                <Stack space={3}>
                  <Heading level={3}>Die Burse</Heading>
                  <Heading color="whisper">La Faculté des arts</Heading>
                </Stack>
                <Paragraph lang="fr">
                  À l'époque de Hölderlin l'immeuble servait comme centre
                  hospitalier de l'université. C'est ici qu'on a soigné
                  Hölderlin. Aujourd'hui la maison appartient toujurs à
                  l'université et héberge les institus de philosophie et de
                  l'histoire de l'art.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={[6, 12]}>
            <Column width={["100%", "1/3"]}>
              <Image
                image={data.stadtfriedhof.childImageSharp.gatsbyImageData}
                alt="La pierre tombale de Hölderlin a la forme d'un obélisque et est entourée de buissons verts et d'arbres. Il y a une croix au sommet de la pierre tombale."
                attribution="Gudrun de Maddalena"
              />
            </Column>
            <Column>
              <Stack>
                <Stack space={3}>
                  <Heading level={3}>Der Stadtfriedhof</Heading>
                  <Heading color="whisper">Le cimétière de la ville</Heading>
                </Stack>
                <Paragraph lang="fr">
                  Au cimétière de la ville se trouve la tombe de Hölderlin.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
        </Stack>

        <Constrain align="right">
          <Stack>
            <Heading as="h2" level={2}>
              La Société Hölderlin
            </Heading>
            <Paragraph lang="fr">
              La{" "}
              <TextLink href="https://www.hoelderlin-gesellschaft.de/">
                Société Hölderlin
              </TextLink>{" "}
              compte presque 900 membres dans le monde entier, dont des lecteurs
              et des lectrices, des chercheurs et chercheuses et beaucoup
              d'institutions liées à la littérature. En tant que société
              littéraire elle veut approfondir l'approche de l'œuvre de
              Friedrich Hölderlin et encourager l'étude et la présentation de sa
              poésie, de sa vie et de son époque.
            </Paragraph>
          </Stack>
        </Constrain>
      </SectionSpacing>
    </Layout>
  )
}

export default Francais
